@import 'theme/variables';
$theme-deafult: #ff4c3b;
@import 'theme/style';
@import 'theme/menu';
@import 'theme/responsive';

.header-new .main-menu {
    z-index: 11;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 750px;
    margin: 10px auto 0px auto;
    box-shadow: 0px 4px 8px 4px rgba(0,0,0,0.15);
    border-radius: 10px;
    background-color: white;
    padding-right: 30px;
    text-align: center;
    padding-left: 30px;

}

.header-new .ul{
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    margin-top: 10px;
    list-style-type: none;
    flex: 1;
}

.header-new .li {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.header-new .font
{
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

.header-new .sub-menu{
    width: 900px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -10px auto 40px auto;
    box-shadow: 0px 4px 5px 3px rgba(0,0,0,0.15);
    border-radius: 10px;
    background-color: white;
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: center;
    padding-right: 30px;
    z-index: 9;
    padding-left: 30px;
}

.header-new .li-list{
    display: flex;
    align-items: start;
    flex: 0 0 18%;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);
    min-height: 95px;
}
